import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    name: "homeview",
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/Home.vue"),
        meta: {
          title: "首页",
        },
      },
      // 学校概况
      {
        redirect: "/schoolintroduction",
        path: "/about",
        name: "about",
        component: () => import("../views/AboutView.vue"),
        meta: {
          title: "学校概况",
        },
        children: [
          {
            path: "/schoolintroduction", //学校简介
            name: "schoolintroduction",
            component: () => import("../views/schoolintroduction.vue"),
            meta: {
              title: "学校简介",
            },
          },
          {
            path: "/schoolset", //机构设置
            name: "schoolset",
            component: () => import("../views/schoolset.vue"),
            meta: {
              title: "机构设置",
            },
          },
          {
            path: "/schoolqualifications", //企业资质
            name: "schoolqualifications",
            component: () => import("../views/schoolqualifications.vue"),
            meta: {
              title: "企业资质",
            },
          },
          {
            path: "/schoolfacilities", //培训设施
            name: "schoolfacilities",
            component: () => import("../views/schoolfacilities.vue"),
            meta: {
              title: "培训设施",
            },
          },
          {
            path: "/contactUs",
            name: "contactUs",
            component: () => import("../views/contactUs.vue"),
            meta: {
              title: "联系我们",
            },
          },
        ],
      },
      //新闻动态
      {
        path: "/new",
        name: "new",
        component: () => import("../views/NewView.vue"),
        meta: {
          title: "新闻动态",
        },
        children: [
          {
            path: "/newinformation", //培训资讯
            name: "newinformation",
            component: () => import("../views/newnewinformation.vue"),
            meta: {
              title: "培训资讯",
            },
          },
          {
            path: "/newnotice", //培训通知
            name: "newnotice",
            component: () => import("../views/newnotice.vue"),
            meta: {
              title: "培训通知",
            },
          },
          {
            path: "/download", //下载中心
            name: "download",
            component: () => import("../views/download.vue"),
            meta: {
              title: "下载中心",
            },
          },
          {
            path: "/newindustry",
            name: "newindustry",
            component: () => import("../views/newindustry.vue"),
            meta: {
              title: "行业资讯",
            },
          },
        ],
      },
      // 业务范围
      {
        path: "/business",
        name: "business",
        component: () => import("../views/businessView.vue"),
        children: [
          // {
          //   path: "/businesschange", //修改
          //   name: "businesschange",
          //   component: () => import("../views/businesschange.vue"),
          //   meta: {
          //     title: "燃气经营企业从业人员培训取证",
          //   },
          // },

          // {
          //   path: "/businesstrain", //建筑行业技术工人培训
          //   name: "businesstrain",
          //   component: () => import("../views/businesstrain.vue"),
          //   meta: {
          //     title: "建筑行业技术工人培训",
          //   },
          // },

          // {
          //   path: "/businessPost", //现场管理人员上岗取证培训
          //   name: "businessPost",
          //   component: () => import("../views/businessPost.vue"),
          //   meta: {
          //     title: "现场管理人员上岗取证培训",
          //   },
          // },
          // {
          //   path: "/businessengineering", //工程人员继续教育
          //   name: "businessengineering",
          //   component: () => import("../views/businessengineering.vue"),
          //   meta: {
          //     title: "工程人员继续教育",
          //   },
          // },
          // {
          //   path: "/busiqualifications", //建筑企业资质咨询
          //   name: "busiqualifications",
          //   component: () => import("../views/busiqualifications.vue"),
          //   meta: {
          //     title: "建筑企业资质咨询",
          //   },
          // },
          // {
          //   path: "/businessother", //其他职业资格类考试
          //   name: "businessother",
          //   component: () => import("../views/businessother.vue"),
          //   meta: {
          //     title: "其他职业资格类考试",
          //   },
          // },
          // {
          //   path: "/businessSpecial", //建筑施工特种作业人员培训
          //   name: "businessSpecial",
          //   component: () => import("../views/businessSpecial.vue"),
          //   meta: {
          //     title: "建筑施工特种作业人员培训",
          //   },
          // },
        ],
      },
      //政策法规
      {
        path: "/policylaw",
        name: "policylaw",
        component: () => import("../views/policylaw.vue"),
        children: [
          {
            path: "/policytrain",
            name: "policytrain",
            component: () => import("../views/policytrain.vue"),
          },
          {
            path: "/policyflair",
            name: "policyflair",
            component: () => import("../views/policyflair.vue"),
          },
          {
            path: "/policysafeprod",
            name: "policysafeprod",
            component: () => import("../views/policysafeprod.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
