<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script >
export default {
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    (function () {
      if (window.history && window.history.pushState) {
        window.onpopstate = function () {
			// window.location.href="/"
			location.replace("/")
        };
      }
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    })();


    // ---适配
    this.bodyScale()
      window.onresize = () => {
        return (() => {
          this.bodyScale()
        })()
      }

  },
 
  methods:{
    bodyScale(){
      let devicewidth = document.documentElement.clientWidth//获取当前分辨率下的可是区域宽度
      // console.log("devicewidth",devicewidth);
      if(devicewidth<1200){
        let scale = devicewidth / 1200 // 分母——设计稿的尺寸
        document.body.style.zoom = scale//放大缩小相应倍数
      }
    }
  }
};
</script>
<style lang="scss">
@import "./assets/quill.css";
* {
  margin: 0;
  padding: 0;
}
.contents{
padding: 20px;
}
.cont_right{
margin-top: 18px;
}
html,
body,
#app {
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  font-family: "新宋体", Arial, Helvetica, sans-serif, "新宋体";
  -webkit-text-size-adjust: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  text-decoration: none; /* 去除下划线 */
  color: inherit; /* 继承父元素的颜色 */
  cursor: pointer; /* 修改鼠标指针样式 */
}
</style>
