import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    article: [],
    total: 0,
    id: 0,
    isdetail: false,
    detailcontent: {},
    activeitem: {},
    navisno:1,
  },
  getters: {},
  mutations: {
    SET_ARTICLE(state, val) {
      state.article = val.article;
      state.total = val.total;
      state.id = val.id;
    },
    SET_ISDETAIL(state) {
      state.isdetail = false;
    },
    SET_DETAIL(state) {
      state.isdetail = true;
    },
    SET_DETAILCONTENT(state, val) {
      state.detailcontent = val;
    },
    SET_ACTIVEITEM(state, val) {
      state.activeitem = val;
    },
    SET_NAVISNO(state,val){
    state.navisno=val;
    }
  },
  actions: {},
  modules: {},
});
